/* Styles go here */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
#spinner{
    height:100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
body{
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: 'IBM Plex Sans', sans-serif;
	overflow-x: hidden;
	overflow-wrap: break-word;
}
nav{
	margin: 0px;
	padding: 0px;
	height: 65vh;
	width: 100vw;
	background-image: url(https://assemblyvoting.com/wp-content/uploads/Photo_8-1024x703.jpg);
	background-size: inherit;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
}
#Label{
	font-size: 25px;
	display: none;
}
#bars{
	display: none;
}
nav h3{
	margin: 0px;
	font-size: 25px;
	padding: 20px 10px 10px 30px;
}
nav ul{
	list-style: none;
	margin-left: 70%;
	margin-top: -40px;
}
nav ul li{
	display: inline-block;
}
select{
	border: none;
	outline: none;
	border-bottom: 1px solid black;
}
#top_candidates img{
	width: 200px;
	height: 200px;
	margin: 20px 20px 20px 20px;
	border-radius: 50%;
}
footer{
	height: 200px;
}
footer a{
	padding: 10px 10px 10px 10px;
}
#Socials{
	font-size:25px
}
@media(max-width: 1360px){
	nav ul{
		margin-left: 60%;
	}
}
@media(max-width: 1010px){
	nav ul{
		margin-left: 50%;
	}
}
@media(max-width: 812px){
	#top_candidates img{
		width: 150px;
		height: 150px;
	}
	nav ul li{
		display: none;
	}
	nav ul{
		margin: auto;
		margin-top: 10%;
		line-height: 50px;
		transition: 0.5s all ease-in-out;
	}
	#Label{
		display: block;
		margin-top: -37px;
		margin-left: 87%;
	}
	#bars:checked~ul li{
		display: block;
	}
}
#Modal{
	width:350px;
	height:400px;
	border:1px solid lightgray;
	background-color: lightyellow;
	border-radius: 1rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
#Modal label{
	font-size:20px;
}